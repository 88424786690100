.footer {
  background-color: #002856;
  font-family: 'Montserrat', serif;
  color: white;
  font-weight: 500;
}

.footer a {
  text-decoration: none;
  font-size: 15px;
  color: white;
  margin-bottom: 10px;
}

.footer a:hover {
  color: #B3A168;
}

.footer-right img {
  width: 150px;
  margin: 32px -120px 30px 0;
}

/* FOOTER -> MOBILE ================================ */

@media (max-width: 600px) {
  .footer-left {
    display: none;
  }

  .footer-right {
    padding: 40px 0 40px 0;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 70px;
  }

  .footer-right img {
    width: 180px;
    margin: 0 auto;
    margin-top: -60px;
    margin-bottom: -68px;
  }

  .footer a {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

/* FOOTER -> DESKTOP ================================ */

@media (min-width: 601px) {
  .footer {
    display: flex;
    justify-content: space-between;
  }

  .footer-right {
    text-align: right;
    padding: 10px 0 10px 15px;
    margin: 0 60px 0 0;
  }

  .footer-left {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 30px 0 30px 60px;
    line-height: 1em;
  }
}