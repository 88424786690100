.hero-image {
  background-image: url("https://cdn.bfldr.com/OPKE8V6B/at/wkv9qmzpp9b4skbhstvt7z/FSV_home_hero.jpg?auto=webp&format=jpg");
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
}

.color-overlay {
  background-color: rgba(0, 40, 86, 0.58);
  height: 800px;
}

.hero-text img {
  text-align: center;
  position: absolute;
  width: 25vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-subheader {
  background-color: #002856;
  padding: 24px 40px 24px 40px;
}

.home-subheader h2 {
  color: #B3A168;
  font-size: 26px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.main-text {
  color: white;
  font-size: 18px;
  line-height: 1.8;
  font-weight: 400;
}

.home-title-text {
  color: #002856;
  font-size: 26px;
  margin: 4px 0 30px 40px;
}

.home-text {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.8;
}

.home-project {
  margin: 80px 0 80px 0;
}

.home-project-container {
  display: flex;
  align-items: center;
  column-gap: 40px;
  width: 90%;
  margin-left: 105px;
  flex-wrap: wrap;
  row-gap: 40px;
}

.home-project-list-box {
  position: absolute;
}

.home-project-list-box img {
  height: 300px;
  width: 300px;
  object-fit: cover;
}

.home-project-list-box-overlay {
  height: 300px;
  width: 300px;
  position: relative;
  background-color: rgba(0, 40, 86, 0.68);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-project-list-box-overlay:hover {
  background-color: rgba(0, 40, 86, 1);
  cursor: pointer;
}

.home-project-list-box-overlay h3:first-child {
  margin: -12px 0 0 0;
  text-align: center;
  color: white;
  font-size: 36px;
  font-family: 'Montserrat';
}

.home-project-list-box-overlay h3:last-child {
  margin: 0;
  text-align: center;
  color: #B3A168;
  font-size: 38px;
  margin-top: -6px;
}

.home-project-progress-link {
  background-color: #B3A168;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  padding: 14px;
  color: white;
  border: none;
  cursor: pointer;

}

.home-progress-pop {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.home-progress-pop a {
  text-decoration: none;
  color: #002856;
  font-weight: 500;
  border: 1px solid #002856;
  padding: 14px;
  background-color: #ffffff;
}

.home-progress-pop a:hover {
  color: #B3A168;
  border: 1px solid #B3A168;
}

.home-project-progress-link:hover {
  cursor: pointer;
  background-color: #002856;
}

.display-none {
  display: none;
}

.home-video {
  margin: 70px 165px 70px 150px;
  display: flex;
  justify-content: center;
  column-gap: 40px;
}

.home-video video {
  height: 38vw;
}

.home-highlights {
  background-color: #002856;
  display: flex;
  align-items: center;
  column-gap: 40px;
  overflow: hidden;
  padding: 22px 0 27px 0;
}

.home-highlights h2 {
  color: #ffffff;
  font-size: 26px;
  margin-bottom: -20px;
  padding-left: 5rem;
}

.home-highlights ul {
  color: white;
  padding: 2rem 0 0 5rem;
  list-style-type: none;
}

.home-highlights ul li {
  padding: 0 0 1.4rem 2rem;
  background-image: url(../graphics/SV-goldbullet.png);
  background-position: 0 0;
  background-size: 1.2rem 1.2rem;
  background-repeat: no-repeat;
}

.bold {
  font-weight: 700;
  color: #B3A168;
}

.home-highlights img {
  width: 260px;
  height: 260px;
  opacity: 0.5;
  margin-right: -130px;
}


.home-history {
  margin: 80px 0 80px 0;
}

.home-history p {
  margin: -10px 40px 30px 40px;
}

.home-history-image-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 38px;
  row-gap: 38px;
  margin: 0 auto;
  width: 90%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
}

.home-history-image-container img {
  height: 250px;
  width: 250px;
  object-fit: cover;
  position: absolute;
}

.home-history-button {
  background-color: #B3A168;
  text-decoration: none;
  font-weight: 500;
  padding: 14px;
  color: white;
  border: none;
  margin-left: 40px;
}

.home-history-button:hover {
  background-color: #002856;
}

.svv-view-master-button {
  background-color: #B3A168;
  text-decoration: none;
  font-weight: 500;
  padding: 14px;
  color: white;
  border: none;
  margin-left: 40px;
}

.svv-view-master-button:hover {
  background-color: #002856;
}

.button-bottom-margin {
  margin-bottom: 130px;
}

.home-history-image-container a:hover {
  cursor: pointer;
  background-color: #002856;
}

.home-history-image-overlay {
  height: 250px;
  width: 250px;
  position: relative;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
}

.home-history-image-overlay:hover {
  opacity: 100;
}

.home-history-image-overlay h4 {
  font-weight: 600;
  width: 86%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 22px;
}

.home-history-image-overlay img {
  height: 45px;
  width: 45px;
  position: relative;
  margin: 0 auto;
  margin-top: 6px;
  opacity: 0.5;
}

.home-history-image-overlay h4:hover {
  font-weight: 600;
  width: 86%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 22px;
}

.home-history-image-overlay:hover {
  background-color: rgba(0, 40, 86, 1);
  cursor: pointer;
}

.home-sv-container {
  position: relative;
}

.home-sv-container img {
  height: 98vh;
  width: 100%;
  object-fit: cover;
  margin-bottom: 80px;
}

.home-sv-link {
  background-color: white;
  width: 450px;
  position: absolute;
  top: 48px;
  left: 48px;
  border-radius: 20px;
}

.home-sv-link a {
  display: flex;
  column-gap: 26px;
  align-items: center;
  cursor: pointer;
}

.home-sv-link h3 {
  color: #B3A168;
  font-size: 28px;
  margin: 0;
  padding: 30px 30px 0 30px;
}

.home-sv-link p {
  font-size: 14px;
  font-weight: 500;
  color: #002856;
  padding: 0px 30px 30px 30px;
}

.home-sv-link svg {
  fill: #B3A168;
  padding-right: 24px;
}

.home-sv-link-text {
  border-right: 1px solid rgba(0, 40, 86, .25);
  height: 100%;
}

.learn-more {
  padding: 10px 40px 60px 40px;
}

.learn-more h2 {
  color: #B3A168;
  font-size: 26px;
  margin-top: 4px;
  margin-bottom: 10px;
}

.learn-more a {
  color: #002856;
  font-weight: 500;
}

.learn-more a:hover {
  color: #B3A168;
}

.contacts a {
  color: black;
}

.contacts a:hover {
  color: #B3A168;
  cursor: pointer;
}


/* MOBILE STYLING ------------------------------------------------ */

@media (max-width: 1070px) {
  .home-video {
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
  }

  .home-video video {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 780px) {
  .home-project-container {
    flex-direction: column;
    align-content: center;
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 597px) {
  .home-history-image-container {
    justify-content: center;
  }

  .home-history-button {
    margin-left: 0;
    display: flex;
    width: 40%;
    justify-content: center;
    margin: 0 auto;
  }
  
  .svv-view-master-button {
    margin-left: 0;
    display: flex;
    width: 70%;
    justify-content: center;
    margin: 0 auto;
  }
  
  .button-bottom-margin {
    margin-bottom: 60px;
  }

  .home-history-overlay-mobile {
    width: 90%;
    text-align: center;
    background-color: #002856;
    height: 188px;
    margin-top: -74px;
  }

  .home-history-overlay-mobile h4 {
    font-weight: 600;
    font-size: 13.8px;
    line-height: 22px;
    color: white;
    margin: 0 auto;
    margin-top: 50px;
    width: 90%;
  }
}

@media (min-width: 598px) {
  .home-history-overlay-mobile {
    display: none;
  }
}

@media (max-width: 850px) {

  .hero-image {
    height: 690px;
    background-position: right;
  }

  .hero-text img {
    width: 250px;
  }

  .color-overlay {
    height: 690px;
  }
}

@media (max-width: 760px) {

  .home-highlights h2 {
    padding-left: 3rem;
  }

  .home-highlights ul {
    line-height: 1.4;
    padding-left: 3rem;
  }

  .home-highlights img {
    display: none;
  }

  .home-sv-container {
    margin-bottom: 180px;
    display: flex;
    justify-content: center;
  }

  .home-sv-container img {
    height: 50vh;
  }

  .home-sv-link {
    width: 86%;
    top: 348px;
    left: auto;
  }

  .home-sv-link a {
    column-gap: 0;
    align-items: flex-start;
  }

  .home-sv-link h3 {
    padding: 20px 0 0 30px;
  }

  .home-sv-link p {
    padding: 0px 0 20px 30px;
  }

  .home-sv-link svg {
    padding: 35px 60px 0 0;
  }

  .home-sv-link-text {
    border: none;
  }

  .learn-more {
    padding-top: 38px;
  }

}

@media (max-width: 425px) {

  .home-subheader h2 {
    font-size: 5.5vw;
  }
}