h1,
h2,
h3 {
  font-family: 'Playfair Display', serif;
}

h4 {
  font-family: 'Montserrat', serif;
}

p {
  font-family: 'Montserrat', serif;
}

a {
  text-decoration: none;
  font-family: 'Montserrat', serif;
}

ul li {
  padding: 8px 0px;
}

li {
  font-family: 'Montserrat', serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}