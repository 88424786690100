/* DESKTOP FEED --------------------------------- */

@media (min-width: 761px) {
  .igfeed-container-mobile {
    display: none;
  }
}

.igfeed-container {
  margin-left: 105px;
  margin-bottom: 30px;
  overflow: hidden;
}

.snapwidget-widget {
  border: 0;
  width: 80vw;
  height: 24vw;
}

/* MOBILE FEED --------------------------------- */

@media (max-width: 760px) {
  .igfeed-container {
    display: none;
  }

  .igfeed-container-mobile {
    margin-left: 12vw;
  }

  .snapwidget-widget {
    height: 85vw;
  }
}