.headersubpages .color-overlay {
  background-color: rgba(0, 40, 86, 0.58);
  height: 450px;
}

.headersubpages .hero-text img {
  color: white;
  text-align: center;
  position: absolute;
  width: 31vw;
  top: 55%;
  left: 25%;
  transform: translate(-50%, -50%);
}

@media (max-width: 760px) {
  .headersubpages .hero-text img {
    width: 60vw;
    left: 40%;
  }
}