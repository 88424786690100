.nav {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
}

.header-logo {
  width: 110px;
  margin-top: 15px;
  margin-left: 18px;
}

.nav-desktop {
  width: 100%;
  height: 50px;
  float: right;
  display: flex;
  flex-wrap: row nowrap;
  justify-content: flex-end;
  column-gap: 44px;
  margin-top: 41px;
  margin-right: 44px;
}

.nav-desktop ul {
  font-size: 16px;
  color: white;
  font-weight: 600;
  margin-block-start: 0;
  padding-inline-start: 0;
}

.nav-desktop li {
  list-style-type: none;
  margin-left: 10px;
  line-height: 30px;
}

.nav-desktop a {
  font-size: 16px;
  color: white;
  font-weight: 600;
}

.nav-desktop a:hover {
  color: #B3A168;
}

/* MOBILE NAVIGATION ------------------------------------ */

.bm-burger-button {
  position: absolute;
  width: 25px;
  height: 22px;
  right: 25px;
  top: 32px;
}

.bm-burger-bars {
  background: white;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
  left: 176px;
}

.bm-cross {
  background: white;
}

.bm-menu-wrap {
  margin-right: -82px;
}

.nav-mobile a {
  right: -100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.bm-menu {
  background: #B3A168;
  padding: 4em 1em 0;
  font-size: 1em;
  width: 220px;
  font-weight: 700;
}

.bm-menu a {
  text-decoration: none;
  color: white;
  padding: 0 10px 28px 10px;
}

.burger-item {
  margin: 10px 0px 10px 0px;
}

.bm-menu .link-welcome {
  color: white;
  padding: 10px;
}

@media (max-width: 850px) {
  .nav-desktop {
    display: none;
  }

  .nav-projects,
  .nav-progress {
    right: -100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    text-decoration: none;
    color: white;
    padding: 0 10px 28px 10px;
  }

  .nav-projects ul,
  .nav-progress ul {
    margin: 0;
  }

  .nav-projects li,
  .nav-progress li {
    list-style-type: none;
    margin: 0;
  }

  .nav-projects a,
  .nav-progress a {
    color: #002856;
    font-weight: 700;
    font-size: 14px;
    right: -100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    text-decoration: none;
    padding: 14px 0 0 0;
  }

}

@media (min-width: 851px) {
  .nav-mobile {
    display: none;
  }

  .bm-burger-button {
    display: none;
  }

  .nav-dropdown {
    width: 190px;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 8px;
  }

  .nav-projects {
    width: 82px;
  }

  .nav-progress {
    width: 163px;
  }

  .nav-projects ul:hover {
    color: #B3A168;
    cursor: pointer;
  }

  .nav-progress ul:hover {
    color: #B3A168;
    cursor: pointer;
  }
}