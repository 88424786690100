.progress-header-ws .hero-image {
  background-image: url("https://cdn.bfldr.com/OPKE8V6B/at/ftbkjc94s5v2xhtcknbrz8b/Screenshot_2023-12-13_at_21728_PM.jpeg?auto=webp&format=jpg");
  height: 450px;
  background-position-y: 30%;
  background-position-x: 0%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
}

.progress-header-sr .hero-image {
  background-image: url("https://cdn.bfldr.com/OPKE8V6B/at/7jkp5t7fqg28fqbvbkkxthq/FSV_project_seattleridge_hero.jpg?auto=webp&format=jpg");
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
}

.progress-subheader {
  background-color: #002856;
  padding: 24px 40px 24px 40px;
  margin-bottom: 70px;
}

.progress-subheader h2 {
  color: #B3A168;
  font-size: 26px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.progress-container {
  position: relative;
}

.progress-line {
  display: block;
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 206.4px;
  width: 3px;
  background-color: #B3A168;
}

.progress-line-bullet {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #B3A168;
  margin: 8px 18px 0 18px;
  flex: 0 0 24px;
}

@media (min-width: 951px) {
  .progress-container {
    margin: 0 70px 0 70px;
  }

  .update-container {
    display: flex;
    column-gap: 10px;
    margin-bottom: 90px;
  }

  .update-video {
    flex: 1;
  }

  .update-video iframe {
    width: 29.4vw;
    height: 16.5vw;
  }
}

.update-container h4 {
  flex: 0 0 148px;
  background-color: #002856;
  color: white;
  height: 20px;
  padding: 10px;
  text-align: center;
  margin: 0;
}

@media (max-width: 950px) {
  .update-container {
    margin-bottom: 40px;
  }
}

@media (max-width: 638px) {
  .update-container {
    margin-bottom: 0;
  }
}

.update-text {
  margin-right: 22px;
}

.update-text h3 {
  margin-top: 7px;
  margin-bottom: -5px;
  color: #B3A168;
}

.update-text a {
  color: #002856;
  font-weight: 500;
}

.update-text a:hover {
  color: #B3A168;
}

.ig-feed-progress {
  margin-bottom: 50px;
}

.igfeed-progress-title {
  background-color: #002856;
  color: white;
  width: 358px;
  height: 46px;
  padding-left: 62px;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}


/* MOBILE STYLING --------- */

@media (max-width: 950px) {

  .progress-container {
    margin: 0 auto;
    width: 88%;
  }

  .progress-line {
    display: none;
  }

  .progress-line-bullet {
    display: none;
  }

  .update-text h3 {
    margin-top: 20px;
  }

  .update-video {
    margin-top: 22px;
  }

  .update-video {
    position: relative;
    overflow: hidden;
    width: 87vw;
    height: 55vh;
  }

  .update-video iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 50vw;
    transform: translate(-50%, -50%);
  }

}

@media (max-width: 520px) {
  .igfeed-progress-title {
    width: 284px;
    height: 46px;
    padding-left: 33px;
    font-size: 15px;
    margin-top: -61px;
  }
}

@media (max-width: 746px) {
  .update-text {
    margin-bottom: -60px;
  }
}

@media (max-width: 497px) {
  .update-text {
    margin-bottom: -120px;
  }
}