.projects-header-warmsprings .hero-image {
  background-image: url("https://cdn.bfldr.com/OPKE8V6B/at/mkmvpjqncqbhkhq3mpg6qfh/FSV_project_warmsprings_hero.jpg?auto=webp&format=jpg&width=2000&height=1192");
  height: 450px;
  background-position-y: 28%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
}

.projects-header-seattleridge .hero-image {
  background-image: url("https://cdn.bfldr.com/OPKE8V6B/at/qtfwf88b9rh5v53wbp73j4v/FSV_project_seattleridge_hero2.jpg?auto=webp&format=jpg&width=3000&height=2000");
  height: 450px;
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
}

.projects-header-sunvalleyvillage .hero-image {
  background-image: url("https://cdn.bfldr.com/OPKE8V6B/at/35s48b6p54bjt9g9bz3wgfg/FSV_project_village_hero.jpg?auto=webp&format=jpg&width=2000&height=1372");
  height: 450px;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
}

.color-overlay {
  background-color: rgba(0, 40, 86, 0.58);
  height: 800px;
}

.hero-text img {
  text-align: center;
  position: absolute;
  width: 25vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.projects-subheader {
  background-color: #002856;
  padding: 24px 40px 90px 40px;
}

.projects-subheader h2 {
  color: #B3A168;
  font-size: 26px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.main-text {
  color: white;
  font-size: 18px;
  line-height: 1.8;
  font-weight: 400;
}

.footnote {
  font-style: italic;
  font-size: 15px;
  color: white;
  line-height: 1.8;
  font-weight: 400;
}

.projects-video {
  margin: -70px 165px 40px 150px;
  display: flex;
  justify-content: center;
  column-gap: 40px;
}

.projects-video img {
  width: 20vw;
  height: 20vw;
  margin-top: 300px;
}

.projects-video video {
  height: 38vw;
}

.hero-quote {
  margin: 60px 50px 20px 50px;
}

.hero-quote-text {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}

.hero-quote-text h2 {
  font-family: 'BIZ UDPMincho', serif;
  font-size: 70px;
  font-weight: 700;
  color: #B3A168;
  margin-bottom: -38px;
}

.hero-quote-text p {
  margin: 0;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  text-align: right;
}

.hero-quote h4 {
  text-align: right;
  width: 95%;
}

.quote1 {
  margin-top: 0px;
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.quote2 {
  margin-bottom: 0;
  align-self: flex-end;
}

.project-highlights-container {
  position: relative;
}

.gold-line {
  display: block;
  position: absolute;
  z-index: 1;
  left: 87.5px;
  top: 50px;
  bottom: 34px;
  width: 3px;
  background-color: #B3A168;
}

.project-highlights {
  background-color: #002856;
  display: flex;
  align-items: center;
  column-gap: 40px;
  overflow: hidden;
}

.project-highlights ul {
  color: white;
  padding: 2rem 0 0 5rem;
  list-style-type: none;
  z-index: 2;
}

.project-highlights ul li {
  padding: 0 0 1.4rem 2rem;
  background-image: url(../graphics/SV-goldbullet.png);
  background-position: 0 0;
  background-size: 1.2rem 1.2rem;
  background-repeat: no-repeat;
}

.bold {
  font-weight: 700;
  color: #B3A168;
}

.project-highlights img {
  width: 260px;
  height: 260px;
  opacity: 0.5;
  margin-right: -130px;
}


@media (min-width: 850px) {

  .sidebyside-container,
  .sidebyside-container-right {
    display: flex;
    justify-content: space-around;
    column-gap: 90px;
    height: 388px;
    margin-bottom: 130px;
  }

  .sidebyside-container-right {
    flex-direction: row-reverse;
  }
  
  .faq-container {
    display: flex;
    justify-content: space-around;
    column-gap: 90px;
    margin-bottom: 130px;
  }

  .sidebyside-text {
    width: 500px;
  }

  .sidebyside-image,
  .sidebyside-image-left {
    background-color: #002856;
    width: 500px;
    display: flex;
    align-items: center;
  }

  .sidebyside-image img {
    width: 86%;
    object-fit: cover;
    margin-left: -46px;
  }

  .sidebyside-image-left img {
    width: 86%;
    object-fit: cover;
    margin-left: 120px;
  }

}

@media (max-width: 849px) {

  .sidebyside-container,
  .sidebyside-container-right {
    margin-bottom: 60px;
  }
  
  .faq-container {
    margin-bottom: 60px;
  }

  .sidebyside-image img,
  .sidebyside-image-left img {
    object-fit: cover;
    width: 100%;
  }

}

.chairlift-stats {
  padding: 52px 48px 0 48px;
}


.chairlift-stats h2 {
  color: #B3A168;
  margin-bottom: 40px;
}

.chairlift-stats h3 {
  color: #002856;
  margin: 0;
}

.chairlift-stats ul {
  list-style-type: none;
  margin-left: -38px;
}

.chairlift-stats ul li {
  padding: 0 0 1.1rem 2rem;
  background-image: url(../graphics/SV-goldbullet.png);
  background-position: 0 0;
  background-size: 1.2rem 1.2rem;
  background-repeat: no-repeat;
}

.chairlift-stats .bold {
  color: #000000;
}



.progressupdates-title {
  margin: 4rem 0 1rem 2.47rem;
}

.progressupdates-title ul {
  list-style-type: none;

}

.progressupdates-title ul li {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  color: #002856;
  font-size: 26px;
  line-height: 0.65;
  padding: 0 0 1.4rem 2rem;
  margin-bottom: 45px;
  background-image: url(../graphics/SV-goldbullet.png);
  background-position: 0 0;
  background-size: 1.2rem 1.2rem;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
}

.progressupdates-container {
  margin: 28px 0 96px 66px;
  justify-content: flex-start;
  column-gap: 39px;
  align-items: center;
}

.progressupdates {
  margin: -36px 48px 0 48px;
}

.progressupdates h2 {
  color: #B3A168;
  margin: 0;
}

.progress-link a {
  background-color: #B3A168;
  text-decoration: none;
  font-weight: 500;
  padding: 12px;
  color: white;
  border: none;
}

.progress-link a:hover {
  cursor: pointer;
  background-color: #002856;
}

.projects-igfeed h2 {
  color: #002856;
  font-size: 26px;
  margin: 4px 0 30px 40px;
}


/* MOBILE STYLING ------------------------------------------------ */

@media (min-width: 951px) {

  .progressupdates-container {
    display: flex;
    justify-content: flex-start;
  }

  .progressupdates-video iframe {
    width: 29.4vw;
    height: 16.5vw;
  }
}

@media (max-width: 950px) {

  .progressupdates {
    margin-top: 0;
    margin-bottom: 100px;
  }

  .progressupdates-container {
    margin: 0 auto;
  }

  .progressupdates-video {
    margin-top: 22px;
    position: relative;
    overflow: hidden;
    /* width: 87vw; */
    height: 52vh;
  }

  .progressupdates-video iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 53vw;
    transform: translate(-50%, -50%);
  }

  .progress-link {
    margin-top: 40px;
  }

}

@media (max-width: 400px) {

  .progressupdates-video {
    height: 23vh;
  }

  .progressupdates-video iframe {
    height: 46vw;
  }

}

@media (max-width: 1070px) {
  .projects-video {
    width: 80%;
    margin: 0 auto;
    margin-top: -60px;
  }

  .projects-video video {
    width: 100%;
    height: 100%;
  }

  .projects-video img {
    display: none;
  }
}

@media (max-width: 850px) {

  .hero-image {
    height: 690px;
    background-position: right;
  }

  .hero-text img {
    width: 250px;
  }

  .color-overlay {
    height: 690px;
  }
}

@media (max-width: 760px) {

  .hero-quote {
    margin: 50px 30px 20px 30px;
  }

  .hero-quote h4 {
    font-size: 15px;
  }

  .hero-quote-text p {
    text-align: center;
    font-size: 16px;
    line-height: 26px;
  }

  .project-highlights ul {
    line-height: 1.4;
    padding-left: 3rem;
  }

  .project-highlights img {
    display: none;
  }

  .gold-line {
    display: none;
  }

  .progressupdates-title {
    margin-left: 0.3rem;
  }

  .progressupdates-title ul li {
    margin-left: -30px;
    margin-bottom: -40px;
    background-image: none;
  }
}

@media (max-width: 425px) {

  .projects-subheader h2 {
    font-size: 5.5vw;
  }
}

@media (max-width: 400px) {
  .progressupdates-title ul li {
    margin-bottom: -16px;
  }
}