.history-header .hero-image {
  background-image: url("https://cdn.bfldr.com/OPKE8V6B/as/sbv2fxvmw232r474bsfxbn4/FSV_history_ItAllStartedHere_1?auto=webp&format=png");
  height: 450px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
}

.history {
  margin-bottom: 80px;
  position: relative;
}

.timeline-line {
  display: block;
  position: absolute;
  top: 342px;
  bottom: 0;
  left: 248px;
  width: 3px;
  background-color: #B3A168;
}

.title-year {
  font-size: 1.8em;
  font-family: 'Montserrat', serif;
  display: block;
  position: absolute;
  top: 280px;
  bottom: 0;
  left: 280px;
  color: white;
  height: 100px;
}

.title-year h3 {
  font-family: 'Montserrat', serif;
}

.title-year-bullet {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  border: 4px solid #B3A168;
  margin-top: 11px;
  display: block;
  position: absolute;
  top: 311px;
  bottom: 0;
  left: 236px;
}

.history-container {
  margin-top: 50px;
}

.timeline-header {
  width: 280px;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  column-gap: 35px;
}

.timeline-header h2 {
  margin: 0;
  padding-top: 9px;
  padding-bottom: 10px;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 24px;
  color: #002856;
}

@media (min-width: 760px) {

  .timeline-header h2 {
    width: 152px;
    text-align: right;
  }

  .timeline-content {
    width: 58%;
    margin-left: 298px;
    margin-top: -48px;
  }

}

.timeline-bullet {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #B3A168;
  margin-top: 11px;
}

.timeline-content h3 {
  font-size: 1.3em;
  margin: 0;
  padding-top: 8px;
  padding-bottom: 5px;
  color: #B3A168;
}

.timeline-content h4 {
  font-weight: 600;
  margin-bottom: -8px;
}

.timeline-content p {
  line-height: 24px;
  width: 60vw;
}

.history-images {
  display: flex;
  justify-content: flex-start;
  column-gap: 12px;
  margin-bottom: 84px;
}

.history-images img {
  width: 14vw;
}

#react-modal-image-img {
  width: 55vw;
}

.history a {
  background-color: #B3A168;
  text-decoration: none;
  font-weight: 500;
  padding: 8px;
  color: white;
  border: none;
}

.history a:hover {
  cursor: pointer;
  background-color: #002856;
}

/* MOBILE STYLING ------------------------------------- */

@media (max-width: 760px) {
  .title-year-bullet {
    display: none;
  }

  .title-year {
    margin-left: -236px;
  }

  .timeline-line {
    display: none;
  }

  .timeline-bullet {
    display: none;
  }

  .timeline-header {
    margin-left: -54px;
  }

  .timeline-content {
    margin-left: 52px;
  }

  .timeline-content h3 {
    margin-top: -10px;
  }

  .timeline-content p {
    width: 75vw;
  }

  .history-images {
    margin-bottom: 40px;
    flex-wrap: wrap;
    width: 90%;
    row-gap: 12px;
  }

  .history-images img {
    width: 25vw;
  }
}