.project-gallery {
  display: flex;
  align-items: center;
  margin-left: 1.5vw;
  margin-top: 80px;
  margin-bottom: 80px;

}

.gallery-container {
  overflow: hidden;
  width: 60%;
  margin-left: 11vw;
  position: relative;
}

.gallery-color-block {
  background-color: #B3A168;
  color: white;
  font-weight: 400;
  padding: 10px 50px 12px 50px;
  margin-top: -42px;
}

.active-image {
  white-space: nowrap;
}

.gallery-item {
  display: inline-block;
  align-items: center;
  justify-content: center;
}

.gallery-item img {
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}

.gallery-item p {
  text-align: center;
  margin: 10px;
  font-weight: 500;
}

.concept-gallery-caption {
  white-space: wrap;
}

.gallery-buttons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: +1;
  width: 100%;
  margin-top: 24%;
}

.gallery-buttons button {
  margin: 8px;
  padding: 10px;
  background-color: rgb(255, 255, 255, 0);
  border: none;
  cursor: pointer;
}

.material-symbols-outlined {
  color: white;
  -webkit-text-stroke: 0.5px black;
  font-size: 40px;
  filter: drop-shadow(0 0 0.6rem black);
}

@media (max-width: 1070px) {
  .gallery-color-block {
    display: none;
  }

  .gallery-container {
    margin: 0 auto;
    width: 80%;
  }
}